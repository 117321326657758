<template>
    <div class="bg-white">
        <!-- Main Section -->
        <section class="bg-blue-500 text-stone-300 text-center py-16 opacity-80"
            style="background-image: url('/images/home/slogan.png'); background-size: cover; background-position: center;">
            <div class="ml-20 mr-20 lg:w-2/6 antialiased">
                <div class="flex items-center">
                    <div class="mr-4">
                        <img width="100" src="/images/home/prepto.png" alt="PREPTO Logo" />
                    </div>
                    <h1 class="text-4xl font-bold mb-0">PREPTO</h1> <!-- Set mb-0 to remove extra margin -->
                </div>
                <p class="text-lg p-5">
                    Revolutionize your exam preparation with PREPTO, the intelligent study companion powered by
                    artificial
                    intelligence.
                </p>
                <p class="text-lg p-5">
                    Say goodbye to long hours of manual content review. PREPTO streamlines your study process by
                    automatically summarizing your study materials, creating categorized topics, and generating key
                    questions—all tailored to your learning preferences.
                </p>
                <a href="#how-it-works" class="bg-white text-blue-500 py-2 px-4 rounded-full mt-8 inline-block">Learn
                    More</a>
            </div>
            <div class="w-4/6"></div>
        </section>

        <!-- Product Functionalities Section with Carousel -->
        <section id="how-it-works" class="mt-12 ml-20 mr-20">
            <h2 class="text-3xl font-bold mb-8 text-center">How PREPTO Works</h2>

            <div class="carousel">
                <div class="carousel-inner" ref="carouselInner">

                    <!-- Functionality 1 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">Seamless Content Upload</h3>
                                <p>PREPTO allows you to effortlessly upload your study materials in various formats,
                                    ensuring a seamless onboarding experience for users.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/upload-icon.png" alt="Upload Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>

                    <!-- Functionality 2 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">AI-Powered Summarization</h3>
                                <p>Experience the power of AI as it analyzes your study content and generates concise
                                    and
                                    targeted summaries, saving you valuable study time.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/ai-icon.png" alt="AI Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>

                    <!-- Functionality 3 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">Intelligent Categorization</h3>
                                <p>PREPTO dynamically categorizes study topics, allowing for personalized study sessions
                                    and easy navigation based on difficulty and relevance.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/categories-icon.png" alt="Categories Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>

                    <!-- Functionality 4 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">Smart Multiple Choice Questions</h3>
                                <p>Generate intelligent multiple-choice questions tailored to your study content,
                                    enhancing
                                    your understanding of key concepts.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/mcq-icon.png" alt="MCQ Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>

                    <!-- Functionality 5 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">Effortless Key Point Extraction</h3>
                                <p>PREPTO identifies and extracts key points from your study materials, providing you
                                    with
                                    condensed insights for efficient learning.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/key-point-icon.png" alt="Key Point Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>

                    <!-- Functionality 6 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">Personalized Study Plans</h3>
                                <p>PREPTO creates personalized study plans based on your learning preferences, ensuring
                                    an
                                    adaptive and effective study experience.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/study-plan-icon.png" alt="Study Plan Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>

                    <!-- Functionality 7 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">Real-time Progress Tracking</h3>
                                <p>Track your progress in real-time, monitor strengths and weaknesses, and receive
                                    actionable insights to optimize your study sessions.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/progress-icon.png" alt="Progress Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>

                    <!-- Functionality 8 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">Interactive Study Dashboard</h3>
                                <p>Access an interactive dashboard that provides a comprehensive overview of your study
                                    materials, progress, and upcoming study tasks.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/dashboard-icon.png" alt="Dashboard Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>

                    <!-- Functionality 9 -->
                    <div class="carousel-item">
                        <div class="flex items-center mb-8">
                            <div class="w-1/2">
                                <h3 class="text-xl font-bold mb-2">Adaptive Difficulty Levels</h3>
                                <p>Experience adaptive difficulty levels that challenge you based on your proficiency,
                                    ensuring continuous improvement in your study outcomes.</p>
                            </div>
                            <div class="w-1/2">
                                <img src="/images/home/difficulty-icon.png" alt="Difficulty Icon" class="w-32 h-32" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact Us Section -->
        <section id="contact-us" class="bg-gray-200 py-16">
            <div class="container mx-auto">
                <h2 class="text-3xl font-bold mb-8 text-center">Contact Us</h2>

                <div class="flex flex-col md:flex-row justify-center items-center">
                    <!-- Contact Form (Replace this with your actual contact form code) -->
                    <form @submit.prevent="submitForm" class="md:w-1/2 max-w-lg">
                        <div class="mb-4">
                            <label for="name" class="block text-sm font-medium text-gray-600">Name</label>
                            <input v-model="name" type="text" id="name" name="name"
                                class="mt-1 p-2 w-full border rounded-md" />
                        </div>
                        <div class="mb-4">
                            <label for="email" class="block text-sm font-medium text-gray-600">Email</label>
                            <input v-model="email" type="email" id="email" name="email"
                                class="mt-1 p-2 w-full border rounded-md" />
                        </div>
                        <div class="mb-4">
                            <label for="message" class="block text-sm font-medium text-gray-600">Message</label>
                            <textarea v-model="message" id="message" name="message" rows="4"
                                class="mt-1 p-2 w-full border rounded-md"></textarea>
                        </div>
                        <button type="submit" class="bg-blue-500 text-white py-2 px-4 rounded-full">Submit</button>
                    </form>

                    <!-- Contact Information -->
                    <div class="mt-8 md:mt-0 md:ml-8 text-center md:text-left">
                        <p class="text-lg mb-4">Have questions or need assistance?</p>
                        <p class="text-gray-600">Feel free to reach out to us:</p>
                        <p class="text-gray-600">Email: info@prepto.pro</p>
                        <p class="text-gray-600">Phone: +1 (778) 847-4748</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
import { showToastMessage } from '~/common/utils';


const carouselInner = ref<HTMLElement | null>(null);
let currentIndex = ref(0);
let interval: any | number | null = null;

const name = ref('');
const email = ref('');
const message = ref('');

const submitForm = async () => {
    try {
        const { data, error } = await useFetch('/api/log', {
            method: 'POST',
            body: {
                email: 'info@prepto.pro',
                message: message.value,
                name: "User has sent a new message",
                notify: true,
                severity: '1',
                status: "Contact Form",
                info: {
                    name: name.value,
                    email: email.value
                }
            }
        });

        if (error.value) {
            showToastMessage('Failed to send message', { type: "error"});
        } else {
            console.log(data);
            showToastMessage('Message sent successfully', { type: "success"});
            // Clear the form fields
            name.value = '';
            email.value = '';
            message.value = '';
        }
    } catch (err) {
        console.error('Form submission error:', err);
        alert('Failed to send message');
    }
};

const showSlide = (index: number): void => {
    const offset = -index * 100 + '%';
    carouselInner.value?.style.setProperty('transform', 'translateX(' + offset + ')');
};

const nextSlide = (): void => {
    currentIndex.value = (currentIndex.value + 1) % carouselInner.value!.children.length;
    showSlide(currentIndex.value);
};

onMounted(() => {
    interval = setInterval(nextSlide, 4000);
});

onUnmounted(() => {
    clearInterval(interval);
});
</script>

<style>
/* Tailwind CSS styles or link your Tailwind CSS file */
/* For simplicity, add styles directly in the page component */
.carousel {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    flex: 0 0 100%;
    box-sizing: border-box;
    padding: 0 1rem;
}
</style>